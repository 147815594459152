import * as React from "react"

const CookingenieSection4 = (props) => {
    return (
        <section className="section-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-16">
                        <div className="image-block">
                            <img
                                src={props.image11x?.sourceUrl}
                                srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                                width={props.image11x?.width}
                                alt={props.image11x?.altText}
                            />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="image-block">
                            <img
                                src={props.image21x?.sourceUrl}
                                srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                                width={props.image21x?.width}
                                alt={props.image21x?.altText}
                            />
                        </div>
                        <div className="image-block">
                            <img
                                src={props.image31x?.sourceUrl}
                                srcSet={props.image32x?.sourceUrl + " 2x, " + props.image31x?.sourceUrl + " 1x"}
                                width={props.image31x?.width}
                                alt={props.image31x?.altText}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default CookingenieSection4;